//Imports
import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql, StaticQuery, Link } from 'gatsby';
import HamburgerMenu from 'react-hamburger-menu';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import headerStyle from './Header.module.scss';

//Component
class MainHeader extends React.Component {
    //State
    state = { 
        mobileOpen: false,
        collapsedHeader: false
    }
    

    //Opens the mobile navigation
    openMobileNav = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }

    //Checks scroll position and updates class
    componentDidMount = () => {
        window.addEventListener('scroll', this.updateNav);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.updateNav);
    }

    updateNav = (e) => {
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollTop < 10 && this.state.collapsedHeader === true) {
            this.setState({collapsedHeader: false });
            this.props.headerCollapsed(this.state.collapsedHeader);
        } else if (scrollTop >= 10 && this.state.collapsedHeader === false ) {
            this.setState({collapsedHeader: true });
            this.props.headerCollapsed(this.state.collapsedHeader);
        }
    }

    //Render
    render() {
        return (
            <StaticQuery query={graphql`
                {
                    allWordpressAcfOptions {
                        edges {
                            node {
                                options {
                                    sponsor_text
                                    company_website
                                    uberflip_logo {
                                        url {
                                            source_url
                                            alt_text
                                        }
                                    }
                                    conex_logo {
                                        alt_text
                                        localFile {
                                            childImageSharp {
                                                fluid(maxWidth: 500) {
                                                    ...GatsbyImageSharpFluid
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    allWordpressWpApiMenusMenusItems(filter:{ name: {eq: "main-menu"}}) {
                        edges {
                            node {
                                items {
                                    title
                                    url
                                    target
                                }
                            }
                        }
                    }
                }
            `} render= { props => 
                {
                    /* -- Variables -- */
                    const headerData = props.allWordpressAcfOptions.edges[0].node.options;
                    const navData = props.allWordpressWpApiMenusMenusItems.edges[0].node.items;

                    /* -- Return -- */
                    return (
                        <header className={`${headerStyle.header} ${this.state.collapsedHeader ? headerStyle.collapsed : ''}`}>
                            {/* -- Top Bar (Sponsor) --*/}
                            <div className={headerStyle.topBar}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center">
                                            <span>{headerData.sponsor_text}</span>
                                            <a href={headerData.company_website} target="_blank" rel="noopener noreferrer">
                                                <img src={headerData.uberflip_logo.url.source_url} alt={headerData.uberflip_logo.url.alt_text} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* -- Bottom (Logo & Navigation) --*/}
                            <div className={headerStyle.bottomBar}>
                                <div className="container">
                                    <div className="row align-items-end">
                                        <div className={`col-5 col-sm-4 ${headerStyle.headerLogo}`}>
                                            <Link to="/">
                                                <Img fadeIn={false} fluid={headerData.conex_logo.localFile.childImageSharp.fluid} alt={headerData.conex_logo.alt_text} />
                                            </Link>
                                        </div>
                                        <div className={`col-7 col-sm-8 ${headerStyle.headerNav}`}>
                                            {/* -- Mobile --*/}
                                            <div className={headerStyle.mobileNav}>
                                                <HamburgerMenu
                                                    isOpen={this.state.mobileOpen}
                                                    menuClicked={this.openMobileNav}
                                                    width={18}
                                                    height={15}
                                                    color='white'
                                                    animationDuration={0.15}
                                                />
                                            </div>
                                            {/* -- Desktop --*/}
                                            <nav aria-label="Main Menu Links" className={headerStyle.desktopNav}>
                                                <ul className="d-flex align-items-center justify-content-end">
                                                    {navData.map(item => {
                                                        if (item.target==='_blank') {
                                                            return (
                                                                <li key={item.title}>
                                                                    <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                                                                </li>
                                                            );
                                                        } else {
                                                            const internalUrl = item.url.replace(/.*\/\/[^/]*/, '');
                                                            return (
                                                                <li key={item.title}>
                                                                    <Link to={internalUrl}>{item.title}</Link>
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* -- Mobile Links --*/}
                            <SlideDown className={`my-dropdown-slidedown ${headerStyle.mobileLinks}`} closed={!this.state.mobileOpen}>
                                <nav aria-label="Mobile Menu Links">
                                    <ul>
                                        {navData.map(item => {
                                            if (item.target==='_blank') {
                                                return (
                                                    <li key={item.title}>
                                                        <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                                                    </li>
                                                );
                                            } else {
                                                const internalUrl = item.url.replace(/.*\/\/[^/]*/, '');
                                                return (
                                                    <li key={item.title}>
                                                        <Link to={internalUrl}>{item.title}</Link>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </nav>
                            </SlideDown>
                        </header>
                    );
                }
            } />
        );
    }
}
        
//Export
export default MainHeader;