//Imports
import React from 'react';
import { Helmet } from 'react-helmet';

import '../../../node_modules/bootstrap/dist/css/bootstrap-reboot.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap-grid.css';
import './Layout.scss';
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';

//Component
class Layout extends React.Component {
    /* State */
    state = { headerCollapsed: false }

    /* -- Adjust the body padding based on collapsed header --*/
    headerCollapsed = (collapsedStatus) => {
        if (collapsedStatus) {
            this.setState({ headerCollapsed: true});
        } else {
            this.setState({ headerCollapsed: false});
        }
    }

    /* -- Global Functions-- */
    componentDidMount() {
        /* -- Insert external scripts to bottom of the body & Smooth -- */
        const bodyScripts = this.props.bodyScripts;

        bodyScripts.map((mainScript) => {
            let script;

            if (mainScript.attributes!==null && mainScript.attributes.includes('noscript')) {
                script = document.createElement("noscript");
            } else if (mainScript.attributes!==null && mainScript.attributes.includes('gdpr')) {
                script = document.createElement("script");
                script.setAttribute('type', 'text/plain');
                script.setAttribute('class','optanon-category-3');
            } else {
                script = document.createElement("script");
            }

            if (mainScript.src!==null) { script.setAttribute('src', mainScript.src); }
            if (mainScript.attributes!==null && mainScript.attributes.includes('async')) { script.setAttribute('async', 'true'); }
            script.innerHTML = mainScript.script;

            document.body.appendChild(script);

            return false;
        });

        /* -- Anchors correctly from another page -- */
        window.setTimeout(() => {
            if (window.location.hash && document.querySelector(window.location.hash)) {
                let offsetValue;
                if (document.querySelector('main').classList.contains('header-collapsed')) {
                    offsetValue = 77;
                } else if (!document.querySelector('main').classList.contains('header-collapsed') && window.outerWidth < 768) {
                    offsetValue = 121;
                } else if (!document.querySelector('main').classList.contains('header-collapsed') && window.outerWidth > 767) {
                    offsetValue = 139;
                }
                window.scrollTo(0, document.querySelector(window.location.hash).offsetTop - offsetValue);
            }
        }, 300);
    }
    
    /* -- Return -- */
    render() {
        /* -- Variables -- */
        const seo = this.props.seo;
        const seoRobots = this.props.seoRobots;
        const seoImage = this.props.seoImage;
        const children = this.props.children;
        const headScripts = this.props.headScripts;

        /* -- Smooth Scroll -- */
        if (typeof window !== "undefined") {
            require("smooth-scroll")('a[href*="#"]', {
                offset: () => {
                    if (document.querySelector('main').classList.contains('header-collapsed')) {
                        return 77;
                    } else if (!document.querySelector('main').classList.contains('header-collapsed') && window.outerWidth < 768) {
                        return 121;
                    } else if (!document.querySelector('main').classList.contains('header-collapsed') && window.outerWidth > 767) {
                        return 139;
                    }
                },
            })
        }

        /* -- Render -- */
        return (
            <React.Fragment>
                {/* -- SEO --*/}
                <Helmet>
                    <title>{seo.yoast_wpseo_title}</title>
                    <meta name="description" content={seo.yoast_wpseo_metadesc} />
                    {seoRobots!==null ? <meta name="robots" content="noindex,nofollow" /> : ''}
                    {seoImage!==null ? <meta property="og:image" content={seoImage.source_url} /> : ''}
                    {seoImage!==null ? <meta name="twitter:image" content={seoImage.source_url} /> : ''}
                    {headScripts.map((mainScript, index) => {
                        const attributes = {
                            ...(mainScript.src!==null ? {src: mainScript.src} : {}),
                            ...(mainScript.attributes!==null && mainScript.attributes.includes('async') ? {async: 'true'} : {})
                        }
                        if (mainScript.attributes!==null && mainScript.attributes.includes('noscript')) {
                            return <noscript key={index}>{mainScript.script}</noscript>
                        } else if (mainScript.attributes!==null && mainScript.attributes.includes('gdpr')) {
                            return <script type="text/plain" class="optanon-category-3" key={index} {...attributes}>{mainScript.script}</script>
                        } else {
                            return <script key={index} {...attributes}>{mainScript.script}</script>
                        }
                    })}
                </Helmet>
                {/* -- Header --*/}
                <MainHeader headerCollapsed={this.headerCollapsed} />
                {/* -- Body --*/}
                <main className={this.state.headerCollapsed ? 'header-collapsed' : ''}>
                    {children}
                </main>
                {/* -- Footer --*/}
                <MainFooter />
            </React.Fragment>
        );
    }
}

//Export
export default Layout;