//Imports
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import footerStyle from './Footer.module.scss';
import FooterChildLinks from './FooterChildLinks';

//Components
class MainFooter extends React.Component {
    //Render
    render() {
        return (
            <footer aria-label="Main Footer" className={footerStyle.footer}>
                <div className="container">
                    <StaticQuery query={graphql`
                        {
                            allWordpressWpApiMenusMenusItems(filter:{ name: {eq: "footer-menu"}}) {
                                edges {
                                    node {
                                        items {
                                            title
                                            object_slug
                                            wordpress_children {
                                                url
                                                title
                                                target
                                            }
                                
                                        }
                                    }
                                }
                            }
                            allWordpressAcfOptions {
                                edges {
                                    node {
                                        options {
                                            company_website
                                            uberflip_logo {
                                                url {
                                                    source_url
                                                    alt_text
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `} render={ props => {
                        /* -- Variables -- */
                        const footerNav = props.allWordpressWpApiMenusMenusItems.edges[0].node.items;
                        const currentYear = new Date().getFullYear();
                        const companyLogo = props.allWordpressAcfOptions.edges[0].node.options.uberflip_logo.url;
                        const companyLink = props.allWordpressAcfOptions.edges[0].node.options.company_website;

                        /* -- Return -- */
                        return (
                            <React.Fragment>
                                {/* -- Top Links -- */}
                                <div className="row">
                                    <nav className={`col-12 ${footerStyle.topFooter}`} aria-label="Footer Menu Links">
                                        <ul className="d-flex">
                                            {footerNav.map( link => 
                                                <FooterChildLinks key={link.title} footerCategory={link} />
                                            )}
                                        </ul>
                                    </nav>
                                </div>
                                {/* -- Copyright -- */}
                                <div className={`row align-items-center ${footerStyle.bottomFooter}`}>
                                    <div className={`col-12 col-sm-6 ${footerStyle.logo}`}>
                                        <a href={companyLink} target="_blank" rel="noopener noreferrer">
                                            <img src={companyLogo.source_url} alt={companyLogo.alt_text} />
                                        </a>
                                    </div>
                                    <div className={`col-12 col-sm-6 ${footerStyle.copyright}`}>
                                        Uberflip - {currentYear} All Rights Reserved
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }} />
                </div>
            </footer>
        );
    }
}

//Exports
export default MainFooter;