//Imports
import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import footerStyles from './FooterChildLinks.module.scss';

//Component
class FooterChildLinks extends React.Component {
    //State
    state = {isClosed: true};

    //Hides/shows child links when clicked
    toggleChildLinks = () => {
        this.setState({isClosed: !this.state.isClosed});
    }

    //Render
    render() {
        return (
            <li key={this.props.footerCategory.title} className={`${footerStyles.linkCategory}`}>
                <span onClick={this.toggleChildLinks}>{this.props.footerCategory.title} <FontAwesomeIcon icon={faChevronDown} /></span>
                <SlideDown className={`my-dropdown-slidedown ${footerStyles.slideElement}`} closed={this.state.isClosed}>
                    <ul>
                        {this.props.footerCategory.wordpress_children.map( childLink =>  {
                            if (childLink.target==='_blank') {
                                return (
                                    <li key={childLink.title}>
                                        <a href={childLink.url} target="_blank" rel="noopener noreferrer">{childLink.title}</a>
                                    </li>
                                );
                            } else {
                                const internalUrl = childLink.url.replace(/.*\/\/[^/]*/, '');
                                return (
                                    <li key={childLink.title}>
                                        <Link to={internalUrl}>{childLink.title}</Link>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </SlideDown>
            </li>
        )
    }
}

//Export
export default FooterChildLinks;